<template>
  <div class="languageChoose">
    <div class="iconDjt">
      <img src="../assets/image/djtIcon.png" alt="">
    </div>
    <div class="chooseWarp">
      <div :class="isSelect === 1 ? 'languageSelect' : 'language'" @click="selectLang(1)">ཨམ་སྐད།</div>
      <div :class="isSelect === 2 ? 'languageSelect' : 'language'" @click="selectLang(2)">ཀན་སུའུ་ཡུལ་སྐད།</div>
      <div :class="isSelect === 3 ? 'languageSelect' : 'language'" @click="selectLang(3)">དབུས་སྐད།</div>
      <div :class="isSelect === 4 ? 'languageSelect' : 'language'" @click="selectLang(4)">简体中文</div>
    </div>
    <div class="querylanguage" @click="query">གཏན་འཁེལ།</div>
  </div>
</template>
<script>
import { Dialog } from 'vant'
import { selectLanguage } from '@/api/secondSelect'
export default {
  data() {
    return {
      isSelect: 1,
      oneTitle: 'ཨམ་སྐད།',
      oneMain: 'ཨ་མདོའི་སྐད་ངོས་འཛིན་པ་ཡིན་ནམ།',
      twoTitle: 'ཀན་སུའུ་ཡུལ་སྐད།',
      twoMain: 'ཀན་སུའུ་ཡུལ་སྐད་ངོས་འཛིན་པ་ཡིན་ནམ།',
      threeTitle: 'དབུས་སྐད།',
      threeMain: 'དབུས་སྐད་ངོས་འཛིན་ཡིན་ནམ།',
      fourTitle: '提示',
      fourMain: '是否选择简体中文为主题语言'
    }
  },
  mounted() {
    this.isSelect = localStorage.getItem('language') === 'anduo' ? 1 : localStorage.getItem('language') === 'gansu' ? 2 : localStorage.getItem('language') === 'weiz' ? 3 : 4
  },
  methods: {
    selectLang(e) {
      this.isSelect = e
    },
    query() {
      Dialog.confirm({
        title: this.isSelect === 1 ? this.oneTitle : this.isSelect === 2 ?  this.twoTitle : this.isSelect === 4 ? this.fourTitle : this.threeTitle,
        message: this.isSelect === 1 ? this.oneMain : this.isSelect === 2 ? this.twoMain : this.isSelect === 4 ? this.fourMain : this.threeMain,
        confirmButtonText: this.isSelect == 4 ?  '确定' : 'གཏན་འཁེལ།',
        cancelButtonText: this.isSelect == 4 ?  '取消' : 'མེད་པར་བཟོ་བ།'
      })
        .then(() => {
          console.log('确认')
          if (localStorage.getItem('language')) {
            this.$router.push('/self')
            localStorage.setItem('language', this.isSelect == 1 ? 'anduo' : this.isSelect == 2 ? 'gansu' : this.isSelect == 3 ? 'weiz' : 'zw')
            this.$i18n.locale = this.isSelect == 1 ? 'anduo' : this.isSelect == 2 ? 'gansu' : this.isSelect == 3 ? 'weiz' : 'zw'
          } else{
            this.$router.push('/')
            localStorage.setItem('language', this.isSelect == 1 ? 'anduo' : this.isSelect == 2 ? 'gansu' : this.isSelect == 3 ? 'weiz' : 'zw')
            this.$i18n.locale =  this.isSelect == 1 ? 'anduo' : this.isSelect == 2 ? 'gansu' : this.isSelect == 3 ? 'weiz' : 'zw'
          }
          selectLanguage({lanType: this.isSelect}).then(res => {
            console.log(res)
          })
        })
        .catch(() => {
          console.log('取消')
        });
    }
  }
}
</script>

<style scoped lang="scss">
.languageChoose {
  width: 100%;
  height: 100%;
  background: url('../assets/image/languageBg.png') 100% no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .iconDjt{
    width: 106px;
    height: 106px;
    margin-top: 80px;
    border-radius: 0px 0px 0px 0px;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .chooseWarp{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 44%;
    .languageSelect{
      width: 363px;
      height: 49px;
      margin-bottom: 10px;
      background: #FFFFFF;
      border-radius: 11px 11px 11px 11px;
      border: 1px solid #4A80FE;
      font-family: PingFang SC, PingFang SC;
      font-weight: bold;
      font-size: 19px;
      color: #6493FF;
      line-height: 49px;
      letter-spacing: 4px;
      text-align: center;
      font-style: normal;
      text-transform: none;
    }
    .language{
      width: 363px;
      height: 49px;
      margin-bottom: 10px;
      text-align: center;
      font-size: 19px;
      line-height: 49px;
      letter-spacing: 4px;
      border-radius: 11px 11px 11px 11px;
      border: 1px solid #EBEBEB;
      background: #FFFFFF;
      color: #8E8E8E;
      font-family: PingFang SC, PingFang SC;
      font-weight: bold;
      font-style: normal;
      text-transform: none;
    }
  }
  .querylanguage{
    width: 350px;
    height: 44px;
    position: absolute;
    bottom: 7%;
    background: linear-gradient( 90deg, #6493FF 0%, #477EFE 100%);
    border-radius: 22px 22px 22px 22px;
    font-family: PingFang SC, PingFang SC;
    font-weight: bold;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 44px;
    letter-spacing: 12px;
    text-align: center;
    font-style: normal;
    text-transform: none;
  }
}
</style>
